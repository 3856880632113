import axios from "axios";
import * as Types from "./types";

import { showSuccess, showError } from "../utils/configToast";
import { manageError } from "../utils/axiosResponseError";
import {CUSTOMER_RETRIEVE} from "./types";

export const retrieveCustomers = (data) => async (dispatch) => {
	let responseData;
	await axios
		.get("/customer", { params: data })
		.then((res) => {
			responseData = res.data;
			dispatch({
				type: Types.CUSTOMER_RETRIEVE,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};
export const newCustomerAction = (data) => async (dispatch) => {
	let responseData;
	await axios
		.post("/customer", data)
		.then((res) => {
			responseData = res.data;
			dispatch({
				type: Types.CUSTOMER_ADD,
				payload: responseData.customer,
			});

			showSuccess(responseData.message);
			return true;
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};
export const updateCustomerAction = (data) => async (dispatch) => {
	let responseData;
	await axios
		.put("/customer", data)
		.then((res) => {
			responseData = res.data;
			dispatch({
				type: Types.CUSTOMER_UPDATE,
				payload: responseData.customer,
			});

			showSuccess(responseData.message);
			return true;
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};

export const deleteCustomerAction = (data) => async (dispatch) => {
	let responseData;
	
	dispatch({
		type: Types.CUSTOMER_DELETE,
		payload: {
			id: data.id,
		},
	});
	
	await axios
		.delete("/customer", {data:data})
		.then((res) => {
			responseData = res.data;
			showSuccess(responseData.message);
			return true;
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};


export const importCustomerAction = (data) => async (dispatch) => {
	let responseData;
	let formData = new FormData();
  	formData.append("file", data);

	await axios
		.post("/customer/import", formData, {
			headers: {
			  "Content-Type": "multipart/form-data",
			},
		  })
		.then((res) => {
			responseData = res.data;
			showSuccess(responseData.message);
			return true;
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};


export const exportCustomerAction = (data) => async (dispatch) => {
	let responseData;
	await axios
		.get("/customer/download", { params: data })
		.then((response) => {
			// responseData = res.data;
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'exported_customers.csv'); //or any other extension
			document.body.appendChild(link);
			link.click();
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};

