import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import Icofont from "react-icofont";

class SideMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: "",
			password: "",
			error: {},
		};
	}

	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		event.preventDefault();

		this.props.logout(this.props.history);
	};

	render() {
		let { pageID } = this.props.pageData;
		let { user } = this.props.auth;
		return (
			<div className="col-md-2 side-menu">
				<nav className="collapse show sidebar-nav pt-4" id="left-nav">
					<div
						className={
							pageID === "home_page"
								? "nav-item active"
								: "nav-item"
						}
					>
						<Link to="/" className="nav-link">
							<span className="icon-text">Customers</span>
						</Link>
						{
							user.type === 'admin' ?
							<Link to="/users" className="nav-link">
								<span className="icon-text">Users</span>
							</Link>:""
						}
					</div>
				</nav>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	logout,
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SideMenu)
);
