import * as Types from "../actions/types";
import {CUSTOMER_RETRIEVE} from "../actions/types";

const init = {
	data: {},
	pagination: {},
	error: {},
};

const userReducer = (state = init, action) => {
	switch (action.type) {
		case Types.USER_RETRIEVE: {
			return {
				...state,
				data: action.payload.response.data,
				pagination: action.payload.response.pagination,
				error: {},
			};
		}

		default:
			return state;
	}
};

export default userReducer;