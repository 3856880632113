import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import Icofont from "react-icofont";
import Modal from 'react-modal';
import NewCustomerForm from '../../components/customers/NewCustomerForm';
import {
	exportCustomerAction,
	importCustomerAction
} from '../../store/actions/customerActions';
import SingleSearchItem
	from "./comp/SingleSearchItem";
import ShowUsers
	from "../../components/customers/showUsers";
import NewUserForm
	from "../../components/customers/NewUserForm";

const {AppSettings} = require('../../config');


class UsersAllPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
			importLoading: false,
		};
	}

	setPageData() {
		this.props.setPageData({
			title: "All users",
			pageID: "users_page",
			totalUsers: "...",
		});
	}

	componentDidMount() {
		this.setPageData();
	}

	componentWilMount() {}

	openModal() {
		this.setState({
			modalIsOpen: true,
		});
	}
	async exportHandler() {
		this.setState({
			'exportLoading': true,
		})
		let {keyword, customQuery} = this.state
		await this.props.exportCustomerAction({
			keyword,
			customQuery
		})
		this.setState({
			'exportLoading': false,
		})
	}

	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
		this.reloadData()
	}

	changeHandler = event => {
		let stateName, stateValue;
		if (event.target.type === 'checkbox') {
			stateName = event.target.name;
			stateValue = event.target.checked;
		} else {
			stateName = event.target.name;
			stateValue = event.target.value;
		}


		this.setState({
			[stateName]: stateValue
		})
	}
	importHandler = async (e) => {
		let file = e.target.files[0];
		// uploadFile(url, file);
		this.setState({
			'importLoading': true,
		})
		await this.props.importCustomerAction(file);
		this.setState({
			'importLoading': false,
		})
		this.reloadData()
	};
	reloadData(){
		let {loadRedID} = this.state
		if(loadRedID > 0){
			loadRedID = loadRedID + 1;
		}else{
			loadRedID = 1;
		}

		console.log(loadRedID)

		this.setState({
			'loadRedID': loadRedID,
		})
	}
	toggleFiltering(){
		let{advanceFiltering} = this.state
		this.setState({
			'advanceFiltering': !advanceFiltering
		})
	}
	setQueryValue(that, name, value){
		that.setState({
			[name]: value
		}, () => {
			// Start of query data
			let { queryFirstName, queryLastName, queryCity, queryState, queryZip, queryCompanyName, queryReferral, queryGoogleReview, queryFacebookReview, queryNote } = that.state;
			let customQuery = [
				queryFirstName,
				queryLastName,
				queryCity,
				queryState,
				queryZip,
				queryCompanyName,
				queryReferral,
				queryGoogleReview,
				queryFacebookReview,
				queryNote,
			]
			that.setState({
				customQuery
			})
		})
	}
	clearQueryValue(that, name, value){
		that.setState({
			queryFirstName: '',
			queryLastName: '',
			queryCity: '',
			queryState: '',
			queryZip: '',
			queryCompanyName: '',
			queryReferral: '',
			queryGoogleReview: '',
			queryFacebookReview: '',
			queryNote: '',
		})
	}

	render() {
		this.props.bodyClassController(this.props.pageData);
		let { pageData, userData } = this.props;
		let { importLoading, exportLoading, customQuery, advanceFiltering } = this.state;

		let userSummery;

		if (userData) {
			userSummery = userData.summery;
		}


		return (
			<>
				<Helmet>
					{" "}
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{`${pageData.title} (${pageData.totalUsers})`}</h1>
									</div>
									<div className="d-lg-inline-flex justify-content-end">
										<span
											className="btn btn-primary ml-3"
											onClick={() => this.openModal()}
										>
											<span className="icon-wrapper">
												<Icofont icon="icofont-plus-circle" />
											</span>
											<span className="icon-text">
												{" add new"}
											</span>
										</span>
										{NewUserAccModal(this)}
									</div>
								</div>

								<div className="page-content">
									<ShowUsers
										customQuery={customQuery}
										keyword={this.state.keyword} load_red_id={this.state.loadRedID} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);

		function NewUserAccModal(that) {
			return (
				<Modal
					isOpen={that.state.modalIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>New user</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.closeModal();
								}}
							>
								<i className="icofont-close-line" />
							</button>
						</div>
						<div className="modal-content">
							<NewUserForm />
						</div>
					</div>
				</Modal>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
	importCustomerAction,
	exportCustomerAction
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersAllPage);
