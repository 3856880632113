import React from "react";
import { connect } from "react-redux";
import { deleteCustomerAction } from "../../store/actions/customerActions";

class ViewUserDetails extends React.Component {
	state = {
		error: {},
		isLoading: false,
		title: "",
		rangeFrom: "",
		rangeTo: "",
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({
			customer: "",
		});
	};

	componentDidMount() {
		let { customer } = this.props;

		// set default info if data provided
		if (customer) {
			this.setState({
				customer: customer,
			});
		}
	}

	render() {
		let { customer } = this.state;

		return (
			<div className="row insert-new">
				<div className="col-md-12">
					<div className="single-section">
						<div className="row customer-details">
							<div className="col-md-6 offset-4">
								<div className="single-item">
									<div className="item-title">First name</div>
									<div className="item-value">{customer?.firstName??<span className="blank">NULL</span>}</div>
								</div>
								<div className="single-item">
									<div className="item-title">Last name</div>
									<div className="item-value">{customer?.lastName??<span className="blank">NULL</span>}</div>
								</div>
								<div className="single-item">
									<div className="item-title">Email</div>
									<div className="item-value">{customer?.email??<span className="blank">NULL</span>}</div>
								</div>
								<div className="single-item">
									<div className="item-title">Rep</div>
									<div className="item-value">{customer?.rep??<span className="blank">NULL</span>}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	deleteCustomerAction
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewUserDetails);
