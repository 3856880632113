import React from "react";
import { connect } from "react-redux";
import { newCustomerAction, updateCustomerAction } from "../../store/actions/customerActions";

class NewCustomerForm extends React.Component {
	state = {
		error: {},
		isLoading: false,
		title: "",
		rangeFrom: "",
		rangeTo: "",
		auth: null,
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({
			firstName: "",
			lastName: "",
			phone: "",
			cell: "",
			email: "",
			website: "",
			referral: "",
			companyName: "",
			dob: "",
			address: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			facebookReview: "",
			googleReview: "",
			notes: "",
		});
	};

	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let {
			firstName,
			lastName,
			phone,
			cell,
			email,
			website,
			referral,
			companyName,
			dob,
			address,
			address2,
			city,
			state,
			zip,
			facebookReview,
			googleReview,
			notes,
			customer,
			rep,
			orderType,
		} = this.state;

		let postData = {
			firstName,
			lastName,
			phone,
			cell,
			email,
			website,
			referral,
			companyName,
			dob,
			address,
			address2,
			city,
			state,
			zip,
			facebookReview,
			googleReview,
			rep,
			orderType,
			notes,
		};

		let submitResult;
		if(customer == null){
			submitResult = await this.props.newCustomerAction(postData);
			if (submitResult != null) {
				this.resetFormData();
			}
		}else{
			await this.props.updateCustomerAction({
				'id': customer.id,
				'update': postData,
			});
		}

		this.stopLoading();
	};

	componentDidMount() {
		let { customer } = this.props;

		// set default info if data provided
		if (customer) {
			this.setState({
				...customer,
				customer: customer,
			});
		}
	}

	render() {
		let { isLoading, error, customer } = this.state;
		let { auth } = this.props;

		return (
			<form onSubmit={this.submitHandler} action="">
				<div className="row insert-new">
					<div className="col-md-6">
						<div className="single-section">
							<div className="section-title">
								<h4>Profile info</h4>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="firstName">
											First name
										</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.title
													? "form-control is-invalid"
													: "form-control"
											}
											name="firstName"
											id="firstName"
											value={this.state.firstName}
											onChange={this.changeHandler}
											required={true}
											placeholder={`Ex. John`}
										/>
										{error.firstName && (
											<div className="invalid-feedback">
												{error.firstName}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="lastName">
											Last name
										</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.title
													? "form-control is-invalid"
													: "form-control"
											}
											name="lastName"
											id="lastName"
											value={this.state.lastName}
											onChange={this.changeHandler}
											placeholder={`Ex. Doe`}
										/>
										{error.lastName && (
											<div className="invalid-feedback">
												{error.lastName}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="dob">
											Date of birth
										</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.dob
													? "form-control is-invalid"
													: "form-control"
											}
											name="dob"
											id="dob"
											value={this.state.dob}
											onChange={this.changeHandler}
											placeholder={`Ex. 14/05/1992`}
										/>
										{error.dob && (
											<div className="invalid-feedback">
												{error.dob}
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="phone">Phone</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.phone
													? "form-control is-invalid"
													: "form-control"
											}
											name="phone"
											id="phone"
											value={this.state.phone}
											onChange={this.changeHandler}
											placeholder={`Ex. (555)-555 5555`}
										/>
										{error.phone && (
											<div className="invalid-feedback">
												{error.phone}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="cell">Cell</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.cell
													? "form-control is-invalid"
													: "form-control"
											}
											name="cell"
											id="cell"
											value={this.state.cell}
											onChange={this.changeHandler}
											placeholder={`Ex. (555)-555 5555`}
										/>
										{error.cell && (
											<div className="invalid-feedback">
												{error.cell}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="email">Email</label>
									</div>
									<div className="col-md-8">
										<input
											type="email"
											className={
												error.email
													? "form-control is-invalid"
													: "form-control"
											}
											name="email"
											id="email"
											value={this.state.email}
											onChange={this.changeHandler}
											required={true}
											placeholder={`Ex. name@domain.com`}
										/>
										{error.email && (
											<div className="invalid-feedback">
												{error.email}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="website">Website</label>
									</div>
									<div className="col-md-8">
										<input
											className={
												error.website
													? "form-control is-invalid"
													: "form-control"
											}
											name="website"
											id="website"
											value={this.state.website}
											onChange={this.changeHandler}
										/>
										{error.website && (
											<div className="invalid-feedback">
												{error.website}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="referral">Referral</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.referral
													? "form-control is-invalid"
													: "form-control"
											}
											name="referral"
											id="referral"
											value={this.state.referral}
											onChange={this.changeHandler}
										/>
										{error.referral && (
											<div className="invalid-feedback">
												{error.referral}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="companyName">Company</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.companyName
													? "form-control is-invalid"
													: "form-control"
											}
											name="companyName"
											id="companyName"
											value={this.state.companyName}
											onChange={this.changeHandler}
										/>
										{error.companyName && (
											<div className="invalid-feedback">
												{error.companyName}
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="notes">Note</label>
									</div>
									<div className="col-md-8">
										<textarea
											type="text"
											className={
												error.notes
													? "form-control is-invalid"
													: "form-control"
											}
											name="notes"
											id="notes"
											value={this.state.notes}
											onChange={this.changeHandler}
											placeholder={`Enter note here`}
										/>
										{error.notes && (
											<div className="invalid-feedback">
												{error.notes}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="single-section">
							<div className="section-title">
								<h4>Address details</h4>
							</div>
							
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="address">Address</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.address
													? "form-control is-invalid"
													: "form-control"
											}
											name="address"
											id="address"
											value={this.state.address}
											onChange={this.changeHandler}
											placeholder={`Enter your address here`}
										/>
										{error.address && (
											<div className="invalid-feedback">
												{error.address}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="address2">Address 2</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.address2
													? "form-control is-invalid"
													: "form-control"
											}
											name="address2"
											id="address2"
											value={this.state.address2}
											onChange={this.changeHandler}
											placeholder={`Enter your address 2 here`}
										/>
										{error.address2 && (
											<div className="invalid-feedback">
												{error.address2}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="city">City</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.city
													? "form-control is-invalid"
													: "form-control"
											}
											name="city"
											id="city"
											value={this.state.city}
											onChange={this.changeHandler}
											placeholder={`Enter your City here`}
										/>
										{error.city && (
											<div className="invalid-feedback">
												{error.city}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="state">State</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.state
													? "form-control is-invalid"
													: "form-control"
											}
											name="state"
											id="state"
											value={this.state.state}
											onChange={this.changeHandler}
											placeholder={`Enter your state here`}
										/>
										{error.state && (
											<div className="invalid-feedback">
												{error.state}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="zip">Zip</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.zip
													? "form-control is-invalid"
													: "form-control"
											}
											name="zip"
											id="zip"
											value={this.state.zip}
											onChange={this.changeHandler}
											placeholder={`Enter your zip here`}
										/>
										{error.zip && (
											<div className="invalid-feedback">
												{error.zip}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>

						<div className="single-section">
							<div className="section-title">
								<h4>Review & rating</h4>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="facebookReview">Facebook review</label>
									</div>
									<div className="col-md-8">
										<textarea
											type="text"
											className={
												error.facebookReview
													? "form-control is-invalid"
													: "form-control"
											}
											name="facebookReview"
											id="facebookReview"
											value={this.state.facebookReview}
											onChange={this.changeHandler}
											placeholder={`Enter facebook review here`}
										/>
										{error.facebookReview && (
											<div className="invalid-feedback">
												{error.facebookReview}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="googleReview">Google review</label>
									</div>
									<div className="col-md-8">
										<textarea
											type="text"
											className={
												error.googleReview
													? "form-control is-invalid"
													: "form-control"
											}
											name="googleReview"
											id="googleReview"
											value={this.state.googleReview}
											onChange={this.changeHandler}
											placeholder={`Enter google review here`}
										/>
										{error.googleReview && (
											<div className="invalid-feedback">
												{error.googleReview}
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="rep">Order type</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.orderType
													? "form-control is-invalid"
													: "form-control"
											}
											name="orderType"
											id="orderType"
											value={this.state.orderType}
											onChange={this.changeHandler}
										/>
										{error.orderType && (
											<div className="invalid-feedback">
												{error.orderType}
											</div>
										)}
									</div>
								</div>
							</div>

							{
								auth.user.type === 'admin'
									?<div className="form-group has-error">
										<div className="row">
											<div className="col-md-4">
												<label htmlFor="rep">Rep</label>
											</div>
											<div className="col-md-8">
												<textarea
													className={
														error.rep
															? "form-control is-invalid"
															: "form-control"
													}
													name="rep"
													id="rep"
													value={this.state.rep}
													onChange={this.changeHandler}
													placeholder={`Enter google review here`}
												/>
												{error.rep && (
													<div className="invalid-feedback">
														{error.rep}
													</div>
												)}
											</div>
										</div>
									</div>
									:""
							}
						</div>

						<div className="single-section ">
							<div className="form-actions text-right d-block">
								<button
									type={isLoading ? "button" : "submit"}
									className="btn btn-primary is-invalid"
								>
									{" "}
									{isLoading ? (
										<i className="fas fa-spinner fa-spin"></i>
									) : (
										customer == null ?"Create Customer":"Update Customer"
									)}{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	newCustomerAction,
	updateCustomerAction
};
export default connect(mapStateToProps, mapDispatchToProps)(NewCustomerForm);
