import * as Types from "../actions/types";
import {CUSTOMER_RETRIEVE} from "../actions/types";

const init = {
	data: {},
	pagination: {},
	error: {},
};

const customerReducer = (state = init, action) => {
	switch (action.type) {
		case Types.CUSTOMER_RETRIEVE: {
			return {
				...state,
				data: action.payload.response.data,
				pagination: action.payload.response.pagination,
				error: {},
			};
		}
		
		case Types.CUSTOMER_ADD: {
			if (state.data) {
				if (action.payload) {
					state.data.unshift(action.payload)
				}
			} else {
				state.data = action.payload.order
			}

			return state
		}
		
		case Types.CUSTOMER_UPDATE: {
			var foundIndex = state.data.findIndex(item => item.id == action.payload.id);
			
			state.data[foundIndex] = action.payload;
			return {
				...state,
			};
		}
		case Types.CUSTOMER_DELETE: {
			let finalData = state.data.filter(item => item.id != action.payload.id)
			return {
				...state,
				data: finalData,
			};
		}

		default:
			return state;
	}
};

export default customerReducer;
