import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import Icofont from "react-icofont";
import Modal from 'react-modal';
import ShowCustomers from '../../components/customers/showCustomers';
import NewCustomerForm from '../../components/customers/NewCustomerForm';
import {
	exportCustomerAction,
	importCustomerAction
} from '../../store/actions/customerActions';
import SingleSearchItem
	from "./comp/SingleSearchItem";

const {AppSettings} = require('../../config');


class CustomersAllPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
			importLoading: false,
			auth: null,
		};
	}

	setPageData() {
		this.props.setPageData({
			title: "All customers",
			pageID: "home_page",
			totalUsers: "...",
		});
	}

	componentDidMount() {
		this.setPageData();
	}

	componentWilMount() {}

	openModal() {
		this.setState({
			modalIsOpen: true,
		});
	}
	async exportHandler() {
		this.setState({
			'exportLoading': true,
		})
		let {keyword, customQuery} = this.state
		await this.props.exportCustomerAction({
			keyword,
			customQuery
		})
		this.setState({
			'exportLoading': false,
		})
	}

	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
		this.reloadData()
	}

	changeHandler = event => {
		let stateName, stateValue;
		if (event.target.type === 'checkbox') {
			stateName = event.target.name;
			stateValue = event.target.checked;
		} else {
			stateName = event.target.name;
			stateValue = event.target.value;
		}


		this.setState({
			[stateName]: stateValue
		})
	}
	importHandler = async (e) => {
		let file = e.target.files[0];
		// uploadFile(url, file);
		this.setState({
			'importLoading': true,
		})
		await this.props.importCustomerAction(file);
		this.setState({
			'importLoading': false,
		})
		this.reloadData()
	};
	reloadData(){
		let {loadRedID} = this.state
		if(loadRedID > 0){
			loadRedID = loadRedID + 1;
		}else{
			loadRedID = 1;
		}

		console.log(loadRedID)

		this.setState({
			'loadRedID': loadRedID,
		})
	}
	toggleFiltering(){
		let{advanceFiltering} = this.state
		this.setState({
			'advanceFiltering': !advanceFiltering
		})
	}
	setQueryValue(that, name, value){
		that.setState({
			[name]: value
		}, () => {
			// Start of query data
			let { queryFirstName, queryLastName, queryDOB, queryBirthMonth, queryOrderType, queryCity, queryState, queryZip, queryCompanyName, queryReferral, queryGoogleReview, queryFacebookReview, queryNote, queryRep } = that.state;
			let customQuery = [
				queryFirstName,
				queryLastName,
				queryDOB,
				queryBirthMonth,
				queryOrderType,
				queryCity,
				queryState,
				queryZip,
				queryCompanyName,
				queryReferral,
				queryGoogleReview,
				queryFacebookReview,
				queryNote,
				queryRep,
			]
			that.setState({
				customQuery
			})
		})
	}
	clearQueryValue(that, name, value){
		that.setState({
			queryFirstName: '',
			queryLastName: '',
			queryDOB: '',
			queryBirthMonth: '',
			queryOrderType: '',
			queryCity: '',
			queryState: '',
			queryZip: '',
			queryCompanyName: '',
			queryReferral: '',
			queryGoogleReview: '',
			queryFacebookReview: '',
			queryNote: '',
		})
	}

	render() {
		this.props.bodyClassController(this.props.pageData);
		let { pageData, userData, auth } = this.props;
		let { importLoading, exportLoading, customQuery, advanceFiltering } = this.state;

		let userSummery;

		if (userData) {
			userSummery = userData.summery;
		}

		return (
			<>
				<Helmet>
					{" "}
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="grid-10">
										<div className="row">
											<div className="col-md-6">
												<span className="btn btn-outline-primary"
													  onClick={() => {this.toggleFiltering()}}
												>
													<span className="icon-wrapper">
														<Icofont icon="icofont-filter" />
													</span>
													<span className="icon-text">Advance filtering</span>
												</span>
											</div>
											<div className="col-md-6 mb-2">
												<div className="input-group-lg">
													<input type="search"
														   name="keyword"
														   onChange={this.changeHandler}
														   className ="form-control h-auto p-1" placeholder="Search..."/>
												</div>
											</div>
											{ advanceFiltering === true &&
												<div
													className="col-md-12">
													<div
														className="filtering-area p-3">
														<div
															className="filtering-items mb-3">
															<SingleSearchItem parentThis={this} onUpdate={this.setQueryValue} title={"First name"}  columnName={"firstName"} name={"queryFirstName"}/>
															<SingleSearchItem parentThis={this} onUpdate={this.setQueryValue} title={"Last name"}  columnName={"lastName"} name={"queryLastName"}/>
															<SingleSearchItem parentThis={this} onUpdate={this.setQueryValue} title={"Date of birth"} columnName={"dob"} name={"queryDOB"}/>
															<SingleSearchItem parentThis={this} onUpdate={this.setQueryValue} title={"Birth month name"} columnName={"birthMonth"} name={"queryBirthMonth"}/>
															<SingleSearchItem parentThis={this} onUpdate={this.setQueryValue} title={"Order type"} columnName={"orderType"} name={"queryOrderType"}/>
															<SingleSearchItem parentThis={this} onUpdate={this.setQueryValue} title={"City"}  columnName={"city"} name={"queryCity"}/>
															<SingleSearchItem parentThis={this} onUpdate={this.setQueryValue} title={"State"}  columnName={"state"} name={"queryState"}/>
															<SingleSearchItem parentThis={this} onUpdate={this.setQueryValue} title={"Zip"}  columnName={"zip"} name={"queryZip"}/>
															<SingleSearchItem parentThis={this} onUpdate={this.setQueryValue} title={"Company name"}  columnName={"companyName"} name={"queryCompanyName"}/>
															<SingleSearchItem parentThis={this} onUpdate={this.setQueryValue} title={"Referral"}  columnName={"referral"} name={"queryReferral"}/>

															{
																auth.user.type === 'admin'
																? <SingleSearchItem parentThis={this} onUpdate={this.setQueryValue} title={"Rep"}  columnName={"rep"} name={"queryRep"}/>
																: ''
															}
															<SingleSearchItem parentThis={this} onUpdate={this.setQueryValue} title={"Google review"}  columnName={"googleReview"} name={"queryGoogleReview"}/>
															<SingleSearchItem parentThis={this} onUpdate={this.setQueryValue} title={"Facebook review"}  columnName={"facebookReview"} name={"queryFacebookReview"}/>
															<SingleSearchItem parentThis={this} onUpdate={this.setQueryValue} title={"Note"}  columnName={"note"} name={"queryNote"}/>
														</div>
														<div
															className="filtering-actions">
															{/*<button*/}
															{/*	className="btn btn-sm btn-outline-primary add-item">*/}
															{/*	<Icofont*/}
															{/*		icon="icofont-plus"/>*/}
															{/*</button>*/}
															<button
																onClick={() => {this.reloadData()}}
																className="btn btn-sm btn-primary">
																Apply filter
															</button>
														</div>
														<div
															className="close-filtering p-2">
															<button
																onClick={() => {this.toggleFiltering()}}
																className="btn-close"/>
														</div>
													</div>
												</div>
											}
										</div>
									</div>

									<div className="header-title">
										<h1>{`${pageData.title} (${pageData.totalUsers})`}</h1>
									</div>
									<div className="d-lg-inline-flex justify-content-end">
										<label className="align-items-center btn btn-link d-flex m-0" for={ importLoading !== true?"upload_csv":"" }>{ importLoading !== true?"Import CSV":"Processing..." }</label>
										<input type="file" name="" id="upload_csv" onChange={this.importHandler} className="d-none" accept="csv"/>

										<font
											// href={`${AppSettings.apiHostUrl}/customer/download`}
											onClick={() => this.exportHandler()}
											className="align-items-center btn btn-link d-flex">
											{exportLoading === true?'Processing...':'Export CSV'}

										</font>

										<span
											className="btn btn-primary ml-3"
											onClick={() => this.openModal()}
										>
											<span className="icon-wrapper">
												<Icofont icon="icofont-plus-circle" />
											</span>
											<span className="icon-text">
												{" add new"}
											</span>
										</span>
										{NewBizAccModal(this)}
									</div>
								</div>

								<div className="page-content">
									<ShowCustomers
										customQuery={customQuery}
										keyword={this.state.keyword} load_red_id={this.state.loadRedID} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);

		function NewBizAccModal(that) {
			return (
				<Modal
					isOpen={that.state.modalIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>New customer profile</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.closeModal();
								}}
							>
								<i className="icofont-close-line" />
							</button>
						</div>
						<div className="modal-content">
							<NewCustomerForm />
						</div>
					</div>
				</Modal>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
	importCustomerAction,
	exportCustomerAction
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomersAllPage);
