import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";
import ReactPaginate from "react-paginate";
import { ViewDate, ViewExactDate } from "../../utils/DateViewer";

import bodyClassController from "../../store/utils/bodyClassController";

import { retrieveCustomers, deleteCustomerAction } from "../../store/actions/customerActions";
import setPageData from "../../store/actions/setPageData";
import ViewCustomerDetails from './ViewCustomerDetails';
import Modal from 'react-modal';
import NewCustomerForm from './NewCustomerForm';
import {
	deleteUserAction,
	retrieveUsers
} from "../../store/actions/userActions";
import ViewUserDetails
	from "./ViewUserDetails";
import NewUserForm
	from "./NewUserForm";

class ShowUsers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ReOrderModalOpen: null,
			expandedItem: null,
			NewTicketModalOpen: false,
			accountType: "",
			autoRenew: false,
			advanceFilter: false,
			dataLoading: false,
			orderBy: "date",
			orderSort: "DESC",
			promoCode: "",
			keyword: "",
			error: {},
			page: 1,
		};
	}
	changeHandler = async (event) => {
		let stateName, stateValue;
		if (event.target.type == "checkbox") {
			stateName = event.target.name;
			stateValue = event.target.checked;
		} else {
			stateName = event.target.name;
			stateValue = event.target.value;
		}

		this.setState(
			{
				[stateName]: stateValue,
			},
			() => {
				console.log("type", event.target.type);
				if (
					event.target.type === "checkbox" ||
					event.target.type === "select-one"
				) {
					this.submitHandler();
				}
			}
		);
	};

	submitHandler = async (event) => {
		if (event != null) {
			event.preventDefault();
		}

		this.setState({
			dataLoading: true,
		});

		let {
			page,
			keyword,
			orderBy,
			orderSort,
		} = this.state;
		let {customQuery} = this.props
		await this.props.retrieveUsers({
			page,
			keyword,
			customQuery,
			orderBy,
			orderSort,
			limit: 10,
		});

		this.props.setPageData({
			...this.props.pageData,
			totalUsers: this.props.userData.pagination.total,
		});

		this.setState({
			dataLoading: false,
		});
	};


	deleteHandler = async (id) => {
		if (window.confirm("Are you sure?") === true) {
			this.closeModal();
			await this.props.deleteUserAction({
				id
			})
			this.submitHandler();
		}
	};


	// set data sorting //
	setSorting = (orderBy, orderSort) => {
		this.setState({ orderBy, orderSort }, () => {
			this.submitHandler();
		});
	};

	componentWillReceiveProps(nextProps) {
		console.log('loadReqID')
		if (nextProps.load_red_id !== this.state.loadReqID) {
			console.log(nextProps.load_red_id)
			this.setState(
				{
					loadReqID: nextProps.load_red_id
				},
				() => {
					this.submitHandler();
				}
			);
		}

		if (nextProps.keyword !== this.state.keyword) {
			this.setState(
				{
					keyword: nextProps.keyword
				},
				() => {
					this.submitHandler();
				}
			);
		}
	}

	componentDidMount() {
		this.submitHandler();
	}

	componentWilMount() {}

	handlePagination = (data) => {
		this.setState(
			{
				page: data.selected + 1,
			},
			() => {
				// this.props.retrieveCustomers({ page: this.state.page })
				this.submitHandler();
			}
		);
	};

	openEditModal(item) {
		this.setState({
			updateModalIsOpen: item,
		});
	}
	closeEditModal() {
		this.setState({
			updateModalIsOpen: false,
		});
	}
	openModal(item) {
		this.setState({
			modalIsOpen: item,
		});
	}
	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
		// this.submitHandler();
	}

	render() {
		this.props.bodyClassController(this.props.pageData);

		let { userData } = this.props;

		let allUsers, userPagination, showPagination;
		if (
			userData &&
			userData.data &&
			this.props.userData.pagination.total > 0
		) {
			allUsers = userData.data.length ? userData.data : null;
			userPagination = userData.pagination ? userData.pagination : null;
			showPagination = userPagination.lastPage > 1;
		}
		return (
			<div className="table-area table-responsive">
			{ViewAccountDetailsModal(this)}
			{UpdateCustomerModal(this)}

				<div className="data-viewer data-users-admin">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>Name</th>
								<th>Email</th>
								<th>Rep</th>
							</tr>
						</thead>
						<tbody>

						{allUsers
							? allUsers.map((item) => {
								let fullName = `${item?.firstName??""} ${item?.lastName??""}`
								return (
									<tr onClick={() => this.openModal(item)} className="cursor-pointer" >
										<td><u>{fullName}</u></td>
										<td>{item.email}</td>
										<td>{item.rep??"::::"}</td>
									</tr>
								);
							  })
							: ""}
						</tbody>
					</table>
				</div>
				<div className="section-pagination">
					<font>
						Showing {userPagination?.start} to{" "}
						{userPagination?.end} out of{" "}
						{userPagination?.total}
					</font>
					{showPagination && (
						<nav className="pagination-nav" aria-label="...">
							<ReactPaginate
								previousLabel={"previous"}
								nextLabel={"next"}
								breakLabel={"..."}
								breakClassName={"break-me"}
								pageCount={userPagination?.lastPage}
								initialPage={userPagination?.page - 1}
								marginPagesDisplayed={2}
								pageRangeDisplayed={5}
								containerClassName={"pagination"}
								previousClassName={"page-item"}
								nextClassName={"page-item"}
								pageClassName={"page-item"}
								subContainerClassName={"pages pagination"}
								activeClassName={"active"}
								pageLinkClassName={"page-link"}
								previousLinkClassName={"page-link"}
								nextLinkClassName={"page-link"}
								onPageChange={this.handlePagination}
							/>
						</nav>
					)}
				</div>
			</div>
		);



		function ViewAccountDetailsModal(that) {
			let customerDetails = that.state.modalIsOpen;
			return (
				<Modal
					isOpen={that.state.modalIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>User profile</h3>
							<div>
								<button className="btn-danger mr-2"
									onClick={()=>{
										that.deleteHandler(customerDetails.id);
									}}
									>Delete</button>
								<button className="btn-info mr-2"
									onClick={()=>{
										that.closeModal();
										that.openEditModal(customerDetails);
									}}
									>Edit</button>
								<button
									className="btn close-modal position-static"
									type={`button`}
									onClick={() => {
										that.closeModal();
									}}
								>
									<i className="icofont-close-line" />
								</button>
							</div>
						</div>
						<div className="modal-content">
							<ViewUserDetails customer={that.state.modalIsOpen}/>
						</div>
					</div>
				</Modal>
			);
		}

		function UpdateCustomerModal(that) {
			return (
				<Modal
					isOpen={that.state.updateModalIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>Update user profile</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.closeEditModal();
								}}
							>
								<i className="icofont-close-line" />
							</button>
						</div>
						<div className="modal-content">
							<NewUserForm userInfo={that.state.updateModalIsOpen}/>
						</div>
					</div>
				</Modal>
			);
		}
	}

	openReOrderModal = (orderInfo) => {
		this.setState({
			ReOrderModalOpen: orderInfo,
		});
	};
	closeReOrderModal = () => {
		this.setState({
			ReOrderModalOpen: null,
		});
	};

	openNewTicketModal = (item) => {
		this.setState({
			NewTicketModalOpen: item.OrderID,
		});
	};

	closeNewTicketModal = () => {
		this.setState({
			NewTicketModalOpen: false,
		});
	};
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
});
const mapDispatchToProps = {
	logout,
	bodyClassController,
	retrieveUsers,
	setPageData,
	deleteUserAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(ShowUsers);
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
