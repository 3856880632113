import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import LoginPage from "./pages/auth/LoginPage";

import AuthChecking from "./components/default/authChecking";

import { connect } from "react-redux";

import { setViewMode } from "./store/actions/authActions";

import configureAxios from "./store/utils/configureAxios";

import PrimaryMenu from "./components/menu/headerPrimaryMenu";
import FooterPrimary from "./components/footer/footerPrimary";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import UsersAllPage from "./pages/user/UsersAllPage";
import CustomersAllPage
	from "./pages/user/CustomersAllPage";

class Routes extends React.Component {
	state = {
		user: "",
		viewMode: null,
		password: "",
		error: {},
	};
	async componentWillMount() {
		configureAxios(); // Run Basic Axios Configure
	}

	componentDidMount() {}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			JSON.stringify(prevProps.auth) !== JSON.stringify(this.props.auth)
		) {
			let viewAsAgent = localStorage.getItem("viewAsAgent");

			let { user } = this.props.auth;

			let viewMode;

			if (user && user.U_Type === "admin" && viewAsAgent === "1") {
				viewMode = "affiliate";
			} else if (user && user.U_Type === "admin") {
				viewMode = "admin";
			} else if (user && user.affiliateStatus === "1") {
				viewMode = "affiliate";
			} else if (user && user.businessAccount === "1") {
				viewMode = "business";
			}
			// this.setState({
			// 	viewMode,
			// });
			this.props.setViewMode(viewMode);
		}
	}

	render() {
		let { isAuthenticated } = this.props.auth;

		// if (viewMode === "affiliate") {
		// 	document.documentElement.style.setProperty(
		// 		"--primaryColor",
		// 		`#39E46E`
		// 	);
		// 	document.documentElement.style.setProperty(
		// 		"--secondaryColor",
		// 		`#24ac4e`
		// 	);
		// } else if (viewMode === "business") {
		// 	document.documentElement.style.setProperty(
		// 		"--primaryColor",
		// 		`#E4AF39`
		// 	);
		// 	document.documentElement.style.setProperty(
		// 		"--secondaryColor",
		// 		`#dba01f`
		// 	);
		// }

		return (
			<BrowserRouter>
				<Switch>
					<Route path="/login" component={LoginPage} />
					<Route
						path="/forgot-password"
						component={ForgotPasswordPage}
					/>
					<Route
						path="/reset-password"
						component={ResetPasswordPage}
					/>

					{(() => {
						console.log('isAuthenticated');
						console.log(isAuthenticated);
						if (isAuthenticated === true) {
							return (
								<Switch>
									<Route
										path="/"
										exact
										component={
											isAuthenticated
												? CustomersAllPage
												: AuthChecking
										}
									/>
									<Route
										path="/users"
										exact
										component={
											isAuthenticated
												? UsersAllPage
												: AuthChecking
										}
									/>
								</Switch>
							);
						} else {
							return (
								<Route
									path="/"
									// exact
									component={AuthChecking}
								/>
							);
						}
					})()}

					{/*<Route*/}
					{/*	path="/promo-code"*/}
					{/*	exact*/}
					{/*	component={*/}
					{/*		isAuthenticated ? PromoCodesPage : AuthChecking*/}
					{/*	}*/}
					{/*/>*/}
					{/*<Route*/}
					{/*	path="/promo-code/new"*/}
					{/*	exact*/}
					{/*	component={*/}
					{/*		isAuthenticated ? NewPromoCodePage : AuthChecking*/}
					{/*	}*/}
					{/*/>*/}
				</Switch>
			</BrowserRouter>
		);
	}
}

// export default App;

const mapStateToProps = (state) => ({
	auth: state.auth,
});
const mapDispatchToProps = {
	PrimaryMenu,
	FooterPrimary,
	setViewMode,
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
