import React from "react";
import { connect } from "react-redux";
import { newCustomerAction, updateCustomerAction } from "../../store/actions/customerActions";
import {
	newUserAction,
	updateUserAction
} from "../../store/actions/userActions";

class NewCustomerForm extends React.Component {
	state = {
		error: {},
		isLoading: false,
		title: "",
		rangeFrom: "",
		rangeTo: "",
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({
			firstName: "",
			lastName: "",
			email: "",
			rep: "",
			password: "",
		});
	};

	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let {
			firstName,
			lastName,
			email,
			rep,
			password,
			userInfo,
		} = this.state;

		let postData = {
			firstName,
			lastName,
			email,
			rep,
			password,
		};

		let submitResult;
		if(userInfo == null){
			submitResult = await this.props.newUserAction(postData);
			if (submitResult != null) {
				this.resetFormData();
			}
		}else{
			await this.props.updateUserAction({
				'id': userInfo.id,
				'update': postData,
			});
		}

		this.stopLoading();
	};

	componentDidMount() {
		let { userInfo } = this.props;

		// set default info if data provided
		if (userInfo) {
			this.setState({
				...userInfo,
				userInfo: userInfo,
				password: "",
			});
		}
	}

	render() {
		let { isLoading, error, customer, userInfo } = this.state;

		return (
			<form onSubmit={this.submitHandler} action="">
				<div className="row insert-new">
					<div className="col-md-6 offset-3">
						<div className="single-section">
							<div className="section-title">
								<h4></h4>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="firstName">
											First name
										</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.title
													? "form-control is-invalid"
													: "form-control"
											}
											name="firstName"
											id="firstName"
											value={this.state.firstName}
											onChange={this.changeHandler}
											required={true}
											placeholder={`Ex. John`}
										/>
										{error.firstName && (
											<div className="invalid-feedback">
												{error.firstName}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="lastName">
											Last name
										</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.lastname
													? "form-control is-invalid"
													: "form-control"
											}
											name="lastName"
											id="lastName"
											value={this.state.lastName}
											required={true}
											onChange={this.changeHandler}
											placeholder={`Ex. Doe`}
										/>
										{error.lastName && (
											<div className="invalid-feedback">
												{error.lastName}
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="email">Email</label>
									</div>
									<div className="col-md-8">
										<input
											type="email"
											className={
												error.email
													? "form-control is-invalid"
													: "form-control"
											}
											name="email"
											id="email"
											value={this.state.email}
											onChange={this.changeHandler}
											required={true}
											placeholder={`Ex. name@domain.com`}
										/>
										{error.email && (
											<div className="invalid-feedback">
												{error.email}
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="password">Rep</label>
									</div>
									<div className="col-md-8">
										<input
											className={
												error.rep
													? "form-control is-invalid"
													: "form-control"
											}
											name="rep"
											id="rep"
											value={this.state.rep}
											required={true}
											onChange={this.changeHandler}
										/>
										{error.rep && (
											<div className="invalid-feedback">
												{error.rep}
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="password">Password</label>
									</div>
									<div className="col-md-8">
										<input
											className={
												error.password
													? "form-control is-invalid"
													: "form-control"
											}
											name="password"
											id="password"
											value={this.state.password}
											required={userInfo == null}
											onChange={this.changeHandler}
										/>
										{error.password && (
											<div className="invalid-feedback">
												{error.password}
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="single-section ">
								<div className="form-actions text-right d-block">
									<button
										type={isLoading ? "button" : "submit"}
										className="btn btn-primary is-invalid"
									>
										{" "}
										{isLoading ? (
											<i className="fas fa-spinner fa-spin"></i>
										) : (
											customer == null ?"Create Customer":"Update Customer"
										)}{" "}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	newUserAction,
	updateUserAction
};
export default connect(mapStateToProps, mapDispatchToProps)(NewCustomerForm);
