import axios from "axios";
import * as Types from "./types";
import configureAxios from "../utils/configureAxios";

import { showSuccess, showError } from "../utils/configToast";
import { manageError } from "../utils/axiosResponseError";

import {
	USER_ADD,
	USER_DELETE,
	USER_RETRIEVE,
	USER_UPDATE
} from "./types";

export const retrieveUsers = (data) => async (dispatch) => {
	let responseData;
	await axios
		.get("/user", { params: data })
		.then((res) => {
			responseData = res.data;
			dispatch({
				type: Types.USER_RETRIEVE,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};

export const newUserAction = (data) => async (dispatch) => {
	let responseData;
	await axios
		.post("/user", data)
		.then((res) => {
			responseData = res.data;
			dispatch({
				type: Types.USER_ADD,
				payload: responseData.customer,
			});

			showSuccess(responseData.message);
			return true;
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};

export const updateUserAction = (data) => async (dispatch) => {
	let responseData;
	await axios
		.put("/user", data)
		.then((res) => {
			responseData = res.data;
			dispatch({
				type: Types.USER_UPDATE,
				payload: responseData.customer,
			});

			showSuccess(responseData.message);
			return true;
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};

export const deleteUserAction = (data) => async (dispatch) => {
	let responseData;

	dispatch({
		type: Types.USER_DELETE,
		payload: {
			id: data.id,
		},
	});

	await axios
		.delete("/user", {data:data})
		.then((res) => {
			responseData = res.data;
			showSuccess(responseData.message);
			return true;
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};
