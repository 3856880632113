import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import imgLogo from "../../assets/images/logo-white.png";

class PrimaryMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: "",
			password: "",
			error: {},
		};
	}

	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		event.preventDefault();

		this.props.logout(this.props.history);
	};

	render() {
		let { auth } = this.props;
		let { user, viewMode } = auth;
		let username;

		try {
			username = auth.user.U_Username;
		} catch (error) {
			console.log("Username", error);
		}
		return (
			<nav className="navbar navbar-expand-lg sticky-top navbar-light bg-light">
				<Link to="/" className="navbar-brand">
					<img src={imgLogo} className="logo" alt="" />
				</Link>
				<button
					className="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon" />
				</button>

				<div
					className="collapse navbar-collapse"
					id="navbarSupportedContent"
				>
					<ul className="navbar-nav mr-auto" />

					<ul className="navbar-nav">
						<div className="mobile-show">
							<li className="nav-item">
								<Link to="/" className="nav-link">
									Customers
								</Link>
							</li>

							<li className="nav-item">
								<font
									className="dropdown-item"
									onClick={this.submitHandler}
									href="#"
								>
									Logout
								</font>
							</li>
						</div>

						<li className="nav-item dropdown ml-auto mobile-hide">
							<div
								className="dropdown-menu"
								aria-labelledby="navbarDropdown"
							>
								<font
									className="dropdown-item "
									onClick={this.submitHandler}
									href="#"
								>
									Logout
								</font>
							</div>
						</li>

						<font
							className="dropdown-item "
							onClick={this.submitHandler}
							href="#"
						>
							Logout
						</font>
					</ul>
				</div>
			</nav>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	logout,
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PrimaryMenu)
);
