export const SET_USER = "SET_USER";
export const USERS_ERROR = "USERS_ERROR";
export const USERS_AUTH_CHECKING = "USERS_AUTH_CHECKING";

export const USER_RETRIEVE = "USER_RETRIEVE";
export const USER_DETAILS = "USER_DETAILS";
export const USER_SET_VIEW_MODE = "USER_SET_VIEW_MODE";
export const USER_ADD = "USER_ADD";
export const USER_UPDATE = "USER_UPDATE";
export const USER_DELETE = "USER_DELETE";

export const CUSTOMER_ADD = "CUSTOMER_ADD";
export const CUSTOMER_UPDATE = "CUSTOMER_UPDATE";
export const CUSTOMER_DELETE = "CUSTOMER_DELETE";
export const CUSTOMER_RETRIEVE = "CUSTOMER_RETRIEVE";

export const PROMO_NEW = "PROMO_NEW";

export const SET_PAGE_DATA = "SET_PAGE_DATA";
export const CARD_RETRIEVE = "CARD_RETRIEVE";
