import { combineReducers } from "redux";
import pageDataReducer from "./pageDataReducer";
import authReducer from "./authReducer";
import userReducer
	from "./userReducer";
import customerReducer
	from "./customerReducer";

const rootReducer = combineReducers({
	pageData: pageDataReducer,
	customerData: customerReducer,
	userData: userReducer,
	auth: authReducer,
});

export default rootReducer;
