import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { isLoggedIn } from "../../store/actions/authActions";
import configureAxios from "../../store/utils/configureAxios";

class AuthChecking extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: "",
			password: "",
			error: {},
		};
	}

	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		event.preventDefault();

		this.props.logout(this.props.history);
	};

	async authCheck() {
		await configureAxios(); // Run Basic Axios Configure

		this.props.isLoggedIn(this.forceLogin);
	}
	forceLogin = () => {
		let { isAuthenticated, authChecking } = this.props.auth;
		if (!authChecking && !isAuthenticated) {
			this.props.history.push("/login");
		}
	};
	componentDidMount() {
		this.authCheck();
	}

	render() {
		// let { user, password, error } = this.state
		// this.authCheck();
		return (
			<div className="page-auth-checking">
				<div className="checking-content">
					{/* <div className="title"><i className="fas fa-spinner fa-spin"></i></div> */}
					{/* <div className="area-logo">
						<img src={imgLogo} className="logo" alt="Make SMM Panel"/>
					</div> */}
					<div className="footer">
						<i className="fas fa-spinner fa-spin"></i> Loading...
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
});
const mapDispatchToProps = {
	isLoggedIn,
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AuthChecking)
);
