import React from "react";
import { connect } from "react-redux";
import { deleteCustomerAction } from "../../store/actions/customerActions";

class ViewCustomerDetails extends React.Component {
	state = {
		error: {},
		isLoading: false,
		title: "",
		rangeFrom: "",
		rangeTo: "",
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({
			customer: "",
		});
	};

	componentDidMount() {
		let { customer } = this.props;

		// set default info if data provided
		if (customer) {
			this.setState({
				customer: customer,
			});
		}
	}

	render() {
		let { customer } = this.state;

		return (
			<div className="row insert-new">
				<div className="col-md-12">
					<div className="single-section">
						<div className="row customer-details">
							<div className="col-md-4">
								<div className="single-item">
									<div className="item-title">First name</div>
									<div className="item-value">{customer?.firstName??<span className="blank">NULL</span>}</div>
								</div>
								<div className="single-item">
									<div className="item-title">Last name</div>
									<div className="item-value">{customer?.lastName??<span className="blank">NULL</span>}</div>
								</div>
								<div className="single-item">
									<div className="item-title">Date of birth</div>
									<div className="item-value">{customer?.dob??<span className="blank">NULL</span>}</div>
								</div>
								<div className="single-item">
									<div className="item-title">Company name</div>
									<div className="item-value">{customer?.companyName??<span className="blank">NULL</span>}</div>
								</div>
								<div className="single-item">
									<div className="item-title">Referral</div>
									<div className="item-value">{customer?.referral??<span className="blank">NULL</span>}</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="single-item">
									<div className="item-title">Phone, Cell</div>
									<div className="item-value"><a href={`tel:${customer?.phone}`}>{customer?.phone}</a>, <a href={`tel:${customer?.cell}`}>{customer?.cell}</a></div>
								</div>
								<div className="single-item">
									<div className="item-title">Email</div>
									<div className="item-value">
										{customer?.email
											? <a href={`mailto:${customer?.email}`}>{customer?.email}</a>
											: <span className="blank">NULL</span>
										}
									</div>
								</div>
								<div className="single-item">
									<div className="item-title">Website</div>
									<div className="item-value">
										{ customer?.website
											? <a href={customer?.website} target="_blank">{customer?.website}</a>
											: <span className="blank">NULL</span>
										}
									</div>
								</div>
								<div className="single-item">
									<div className="item-title">Address</div>
									<div className="item-value">{customer?.address??<span className="blank">NULL</span>}</div>
								</div>
								<div className="single-item">
									<div className="item-title">Address 2</div>
									<div className="item-value">{customer?.address2??<span className="blank">NULL</span>}</div>
								</div>
								<div className="single-item">
									<div className="item-title">City, State, Zip</div>
									<div className="item-value">{customer?.city}, {customer?.state}, {customer?.zip}</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="single-item">
									<div className="item-title">Facebook review</div>
									<div className="item-value">{customer?.facebookReview??<span className="blank">NULL</span>}</div>
								</div>
								<div className="single-item">
									<div className="item-title">Google review</div>
									<div className="item-value">{customer?.googleReview??<span className="blank">NULL</span>}</div>
								</div>
								<div className="single-item">
									<div className="item-title">Rep</div>
									<div className="item-value">{customer?.rep??<span className="blank">NULL</span>}</div>
								</div>
								<div className="single-item">
									<div className="item-title">Order type</div>
									<div className="item-value">{customer?.orderType??<span className="blank">NULL</span>}</div>
								</div>
								<div className="single-item">
									<div className="item-title">Note</div>
									<div className="item-value">{customer?.notes??<span className="blank">NULL</span>}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	deleteCustomerAction
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomerDetails);
