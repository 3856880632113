import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";


class SingleSearchItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: "",
            password: "",
            error: {},
        };
    }

    changeHandler = event => {
        let stateName, stateValue;
        if (event.target.type === 'checkbox') {
            stateName = event.target.name;
            stateValue = event.target.checked;
        } else {
            stateName = event.target.name;
            stateValue = event.target.value;
        }


        let that = this
        this.setState({
            [stateName]: stateValue
        }, () => {
            // Trigger callback function
            let{parentThis, name, columnName} = that.props
            let{queryOperator, queryValue} = that.state
            this.props.onUpdate(parentThis, name, {
                'queryColumn': columnName,
                'queryOperator': queryOperator,
                'queryValue': queryValue,
            })
        })

    }
    componentDidMount(){
        this.setState({
            "queryOperator": "equals"
        })
        // Trigger callback function
        let{parentThis, name, columnName} = this.props
        this.props.onUpdate(parentThis, name, {
            'queryColumn': columnName,
            'queryOperator': 'equals',
            'queryValue': '',
        })
    }

    render() {
        let { queryOperator, queryValue } = this.state;
        let { title, name } = this.props;
        console.log('queryOperator')
        console.log(queryOperator)
        return (
            <div
                className="filtering-single mb-1">
                {/*<button*/}
                {/*	className="btn btn-sm btn-outline-danger remove-item">*/}
                {/*	<Icofont*/}
                {/*		icon="icofont-minus"/>*/}
                {/*</button>*/}
                <input
                    type="text"
                    readOnly={true}
                    name="query-column"
                    id="query-column"
                    value={title}
                    className="query-column"/>
                <select
                    name="queryOperator"
                    id="queryOperator"
                    onChange={this.changeHandler}
                    value={queryOperator}
                    className="query-operator form-control-sm">
                    <option
                        value="equals">Equals
                    </option>
                    <option
                        value="notEquals">Not equals
                    </option>
                    <option
                        value="contains">Contains
                    </option>
                    <option
                        value="notContains">Not contains
                    </option>
                    <option
                        value="isEmpty">Is empty
                    </option>
                    <option
                        value="notEmpty">Not empty
                    </option>
                </select>
                {
                    (queryOperator !== "isEmpty" && queryOperator !== "notEmpty") && <input
                        type="search"
                        name="queryValue"
                        id="queryValue"
                        onChange={this.changeHandler}
                        value={queryValue}
                        className="query-value"/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    pageData: state.pageData,
});
const mapDispatchToProps = {

};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SingleSearchItem)
);
